import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthorizationGuard } from './modules/components/auth/guards/authorization.guard';

import { NotfoundComponent } from './modules/components/notfound/notfound.component';
import { AppLayoutComponent } from "./layout/app.layout.component";
import { ActivaUsuarioComponent } from './modules/components/activa-usuario/activa-usuario.component';


@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', redirectTo: 'landing', pathMatch: 'full' // Cambio aquí
            },
            {
                path: '', component: AppLayoutComponent,
                children: [
                    { path: 'dash', loadChildren: () => import('./modules/components/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthorizationGuard]  },
                    { path: 'uikit', loadChildren: () => import('./modules/components/uikit/uikit.module').then(m => m.UIkitModule) },
                    { path: 'utilities', loadChildren: () => import('./modules/components/utilities/utilities.module').then(m => m.UtilitiesModule) },
                    { path: 'documentation', loadChildren: () => import('./modules/components/documentation/documentation.module').then(m => m.DocumentationModule) },
                    { path: 'blocks', loadChildren: () => import('./modules/components/primeblocks/primeblocks.module').then(m => m.PrimeBlocksModule) },
                    { path: 'catalogos', loadChildren: () => import('./modules/components/catalogos/catalogos.module').then(m => m.CatalogosModule), canActivate: [AuthorizationGuard]  },
                    { path: 'digitalizacion', loadChildren: () => import('./modules/components/digitalizacion/digitalizacion.module').then(m => m.DigitalizacionModule) },
                    { path: 'pages', loadChildren: () => import('./modules/components/pages/pages.module').then(m => m.PagesModule) },
                    { path: `solicitud`,loadChildren: () => import(`./modules/components/solicitudes/solicitudes.module`).then(m => m.SolicitudesModule)},
                    { path: `esaf`,loadChildren: () => import(`./modules/components/esaf/esaf.module`).then(m =>m.EsafModule)},
                    { path: `contralor`, loadChildren: () => import(`./modules/components/contralor/contralor.module`).then(m => m.ContralorModule)},
                    { path: `perfiles`, loadChildren: () => import(`./modules/components/perfiles/perfiles.module`).then(m => m.PerfilesModule) },
                    {path : 'usuarionoactivo', loadChildren: () => import(`./modules/components/usuarionoactivo/usuarionoactivo.module`).then(m => m.UsuarionoactivoModule)}
                ]
            },
            { path: 'auth', loadChildren: () => import('./modules/components/auth/auth.module').then(m => m.AuthModule) },
            { path: 'landing', loadChildren: () => import('./modules/components/landing/landing.module').then(m => m.LandingModule) },
            { path: 'registro', loadChildren: () => import('./modules/components/registro/registro.module').then(m => m.RegistroModule)},
            { path: 'notfound', component: NotfoundComponent },
            { path: 'activausuario/:base64data', component: ActivaUsuarioComponent },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
